ul.customers-dashboard-nav {
  li {
    margin: 0 !important;
  }
}
.customers-list {
  .top {
    h2 {
      &::before {
        margin-right: 10px;
        content: '\f0c0';
        font-family: FontAwesome;
      }
    }
  }
}
.customers-list-nav {
  li {
    margin: auto;
    flex: 1;
    &:first-child {
      margin-left: 20px;
    }
    &:last-child {
      margin-right: 82px;
    }
  }
}
.list-of-orders {
  ul {
    li {
      p.customer-id {
        margin: 0 auto;
        font-size: 22px;
        color: #fff;
        margin-left: 10px;
        width: 30%;
        text-align: center;
        margin-right: 0;
        line-height: 1;
      }
      p.name {
        margin: 0 auto;
        // width: 25%;
        text-align: left;
        line-height: 1;
        color: #fff;
        font-size: 14px;
        width: 50%;
      }
    }
  }
}

.dashboard-management {
  .main {
    .customer-details {
      position: relative;
      // margin: 2px;
      background: $light-brown;
      // height: 100px;
      padding: 10px;
      display: grid;
      grid-template-columns: 0.7fr 1fr;
      @include notebook {
        height: auto;
        padding: 10px;
      }
      > div {
        &:first-child {
          display: flex;
          padding: 10px 0;
        }
        &:last-child {
          display: grid;
          grid-template-columns: 1fr;
          .top {
            display: flex;
            p {
              font-size: 16px;
              color: #fff;
              font-family: $lato;
              font-weight: 300;
              margin: auto;
              @include notebook {
                font-size: 14px;
              }
            }

            p.customer-group {
              font-weight: bold;
              margin-right: 15px;
            }
          }
          .bottom {
            display: flex;
            justify-content: flex-end;

            a.reset-password {
              border: 1px solid #fff;
              padding: 5px 15px 5px 15px;
              text-align: center;
              color: #fff;
              margin: auto;
              border-radius: 5px;
              @include trans;
              &:hover {
                background: lighten($brown, 15%);
              }
              @include notebook {
                font-size: 14px;
                padding: 5px;
              }
            }
            a.delete-customer {
              padding: 5px 15px 5px 15px;
              text-align: center;
              color: #fff;
              margin: auto;
              border-radius: 5px;
              background: #b74343;
              margin-right: 20px;
              margin-left: 0;
              @include trans;
              &:hover {
                background: lighten($brown, 15%);
              }
              @include notebook {
                font-size: 14px;
                padding: 5px;
              }
            }
          }
        }
      }
      .image {
        width: 80px;
        height: 80px;
        text-align: center;
        border-radius: 50%;
        background: url('/img/ABC_logo_2007.png') center center;
        margin-left: 20px;
        display: flex;
        background-size: contain;
        i {
          margin: auto;
        }
      }
      h2.customer-name {
        color: #fff;
        font-family: $lato;
        font-size: 16px;
        margin: auto;
        margin-left: 20px;
        @include notebook {
          font-size: 14px;
        }
        span.company {
          font-size: 22px;
          display: block;
        }
      }
    }
  }
  .customers-main {
    position: relative;
    padding: 15px 0 0 0;
    ul.customers-main-nav {
      width: 100%;
      height: 40px;
      border-bottom: 1px solid #fff;
      display: flex;
      margin: 0;
      padding: 0 25px 0 25px;
      list-style: none;
      @include notebook {
        height: auto;
        padding: 0 10px 0 10px;
      }
      li {
        position: relative;
        margin: 0 auto;
        flex: 1;
        text-align: center;
        padding: 5px;
        border: 1px solid transparent;
        border-bottom: none;
        @include trans;

        a {
          &:hover {
            color: #fff;
          }
          &:focus {
            color: #fff;
          }
        }

        &:nth-child(1) {
          &::before {
            content: '\f0c0';
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }
        &:nth-child(2) {
          &::before {
            content: '\f0d1';
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }
        &:nth-child(3) {
          &::before {
            content: '\f080';
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }
        &:nth-child(4) {
          &::before {
            content: '\f07a';
            font-family: FontAwesome;
            margin-right: 5px;
            color: #fff;
          }
        }
      }
      .active {
        border-color: #fff;
        border-bottom: 0;
      }
    }
  }
}
.personal-info {
  display: flex;
  // border-bottom: 1px solid white;
  // padding-bottom: 25px;
  hr.line {
    width: 100%;
    position: absolute;
    top: 90px;
    opacity: 0.4;
  }
  hr.line2 {
    width: 100%;
    position: absolute;
    bottom: -25px;
    opacity: 0.4;
    margin: 0;
  }

  ul {
    margin-left: 0 !important;
    margin-bottom: 0;
    padding: 0 0 0 25px;
    padding-left: 0 !important;
    flex: 1;
    list-style: none;
    h3 {
      font-size: 18px;
      color: #fff;
      //font-weight: 300;
      margin-bottom: 20px;
      @include notebook {
        font-size: 14px;
      }
    }
    li {
      color: #fff;
      display: block;
      font-size: 14px;
      margin-bottom: 20px;
      font-weight: 400;
      word-wrap: break-word;
      &:last-child {
        margin-bottom: 0;
      }
    }
    p.info {
      padding-left: 10px;
      font-size: 16px;
    }
    .name {
      span {
        margin-left: 50px;
      }
      border-bottom: 1px solid white;
    }
    .company-name {
      &::before {
        content: '\f007';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    .mail {
      &::before {
        content: '\f0e0';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    .phone-number {
      &::before {
        content: '\f095';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    .adress {
      &::before {
        content: '\f041';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    .username {
      &::before {
        content: '\f007';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    .password {
      &::before {
        content: '\f084';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
  }

  .accordion-toggle {
    float: right;
    text-decoration: none !important;
    color: white !important;
  }

  .person-description {
    margin-top: 5px;
    padding: 20px 10px;
    background: rgba(0, 0, 0, 0.4);
  }

  .person-name {
    padding-bottom: 10px;
    cursor: pointer;
  }
}
.legal-info {
  .heading {
    border-bottom: 1px solid white;
    font-size: 18px;
    color: white;
    padding-bottom: 20px;
    @media (max-width: 1200px) {
      font-size: 14px;
    }
  }
  .legal-description {
    ol {
      list-style-type: decimal !important;
    }
    ul {
      list-style-type: inherit !important;
    }
    .ql-size-huge {
      font-size: 32px;
    }
    .ql-size-large {
      font-size: 26px;
    }
    .ql-size-small {
      font-size: 12px;
    }
  }
}
a.edit {
  position: absolute;
  bottom: 0;
  font-size: 16px;
  color: #fff;
  border-radius: 5px;
  width: 240px;
  padding: 5px;
  text-align: center;
  background: $brown;
  @include trans;
  &:hover {
    background: lighten($brown, 15%);
  }
  @include notebook {
    font-size: 14px;
    width: 30%;
  }
}

// ================= CUSTOMER GROUPS

.customer-groups {
  position: relative;
  // display: none;
  @include dark-blur;

  .customer-groups-header {
    position: relative;
    background: $light-brown;
    height: 50px;
    margin: 2px;
    padding: 12px;
    h2 {
      font-family: $lato;
      font-size: 22px;
      font-weight: 300;
      color: #fff;
      &::before {
        content: '\f0cb';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
    form {
      position: absolute;
      width: 330px;
      right: 12px;
      top: 50%;
      transform: translate(0, -50%);
      input[type='search'] {
        width: 100%;
        height: 30px;
        outline: none;
        border: none;
        position: relative;
        background: rgba(255, 255, 255, 0.3);
        padding-left: 30px;
        font-size: 14px;
        font-family: $lato;
        padding-right: 30px;
        color: #fff;
      }
      ::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: #fff;
        font-family: $lato;
        font-size: 14px;
      }
      ::-moz-placeholder {
        /* Firefox 19+ */
        color: #fff;

        font-family: $lato;
        font-size: 14px;
      }
      :-ms-input-placeholder {
        /* IE 10+ */
        color: #fff;
        font-family: $lato;
        font-size: 14px;
      }
      :-moz-placeholder {
        /* Firefox 18- */
        color: #fff;
        font-family: $lato;
        font-size: 14px;
      }
      .fa-search {
        position: absolute;
        color: #fff;
        top: 6px;
        left: 10px;
      }
      .fa-times {
        position: absolute;
        color: #fff;
        top: 6px;
        right: 10px;
        cursor: pointer;
      }
    }
  }
  a.add-new-group {
    position: relative;
    z-index: 10;
    padding: 10px 50px 10px 50px;
    float: right;
    display: block;
    width: 320px;
    border: 1px solid #fff;
    text-align: center;
    margin: 30px 13px 30px 0;
    @include trans;
    &:hover {
      // border-color: $blue-hover;
      color: #fff;
    }
    &:focus {
      color: #fff;
    }
  }
}
ul.customer-groups-list-nav {
  position: relative;
  padding: 90px 0 0 0;
  div.labels {
    display: grid;
    grid-template-columns: 10% 30% 20% 20% 20%;
    position: relative;
    margin: 0;
    padding: 0 0 0 25px;
    width: 70%;
    p {
      color: #fff;
      margin-bottom: 0;
      &::after {
        content: '\f0dc';
        font-family: FontAwesome;
        color: #fff;
        padding-left: 5px;
      }
      &:first-child {
        &::after {
          color: $blue-hover;
        }
      }
    }
  }
  li {
    width: 100%;
    background: $light-brown;
    padding: 0 0 0 25px;
    display: grid;
    grid-template-columns: 70% 30%;
    height: 50px;
    margin-bottom: 1px;
    @include trans;
    &:hover {
      background: #6b6f73;
    }
    > div {
      &:first-child {
        display: grid;
        grid-template-columns: 10% 30% 20% 20% 20%;
      }
      &:last-child {
        display: flex;
      }
    }
    p {
      color: #fff;
      font-size: 14px;
      margin-bottom: 0;
      padding-top: 13px;
    }
    p.id {
      font-size: 22px;
    }
    a.edit-button {
      @include button-round;
      width: 160px;
    }
  }
  a.delete-button {
    @include button-danger;
    width: 160px;
  }
}
ul.new-customer-groups-nav {
  width: 100%;
  height: 40px;
  border-bottom: 1px solid #fff;
  display: flex;
  margin: 25px 0 0 0;
  padding: 0 25px 0 25px;
  list-style: none;
  li {
    position: relative;
    flex: 1;
    max-width: 200px;
    text-align: center;
    padding: 5px;
    border: 1px solid transparent;
    border-bottom: none;
    @include trans;

    a {
      &:hover {
        color: #fff;
      }
      &:focus {
        color: #fff;
      }
      &::before {
        content: '\f15c';
        font-family: FontAwesome;
        margin-right: 5px;
      }
    }
  }
  .active {
    border-color: #fff;
    border-bottom: 0;
  }
}
.add-new-group {
  position: relative;
  display: flex;
  padding: 25px 50px;
  form {
    input {
      outline: none;
      box-shadow: none;
      font-family: $lato;
      font-weight: 300;
      color: white !important;
      background: transparent;
    }
    .input::after {
      border-bottom: 2px solid white !important;
    }
    .formRow {
      margin: 20px 0;
    }
    label {
      color: #fff;
      font-family: $lato;
    }
    textarea {
      // margin-left: 177px;
      resize: vertical;
      // border: 1px solid #fff;
      // margin-bottom: 50px;
      // margin-top: -27px;
    }
    button[type='submit'] {
      border: none;
      box-shadow: none;
      outline: none;
      font-size: 16px;
      color: #fff;
      border-radius: 5px;
      // width: 240px;
      padding: 5px;
      text-align: center;
      cursor: pointer;
      background: $brown;
      @include trans;
      &:hover {
        background: lighten($brown, 15%);
      }
      @include notebook {
        font-size: 14px;
        // width: 160px;
      }
    }
  }
  .select-holder {
    position: relative;
    max-width: 299px;
    margin-bottom: 20px;
  }
  .select-holder .select2-dropdown {
    background: $light-brown !important;
    max-width: 124px;
  }
  .select2-container--default .select2-selection--single {
    background: transparent;
    border: 1px solid #aaa;
  }
  .select2-container--default
    .select2-selection--single
    .select2-selection__rendered {
    background: transparent;
  }
  .select-holder.select2-container--default
    .select2-results
    > .select2-results__options {
    background: $light-brown !important;
  }
}
.ql-toolbar.ql-snow {
  background: white !important;
}
.ql-editor p,
.ql-editor ol,
.ql-editor ul,
.ql-editor pre,
.ql-editor blockquote,
.ql-editor h1,
.ql-editor h2,
.ql-editor h3,
.ql-editor h4,
.ql-editor h5,
.ql-editor h6 {
  color: black !important;
}
.ql-editor {
  height: 300px !important;
  background-color: white !important;
}

// ================================ >> PRODUCT TAB

.product-tab {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.enabled-categories .select2-container {
  display: block;
  position: relative !important;
  margin-top: 25px;
}
.enabled-categories .select2-results__option {
  color: #fff;
}
.enabled-categories .select2-search--dropdown input.select2-search__field {
  background: transparent;
}
.enabled-categories.select2-dropdown {
  display: block;
  background: $light-brown !important;
}
.enabled-categories.select2-container--default
  .select2-results
  > .select2-results__options {
  background: $light-brown !important;
  color: #fff;
}
.enabled-categories .select2-container--default .select2-selection--single {
  background: transparent !important;
  border: 1px solid #aaa;
}
.enabled-categories .select2-container--default .select2-selection--multiple {
  background: transparent;
}
.enabled-categories
  .select2-container--default
  .select2-selection--single
  .select2-selection__rendered {
  background: transparent;
  border: 1px solid #aaa;
}
.enabled-categories
  .select2-container--default
  .select2-selection--multiple
  .select2-selection__choice {
  background: transparent;
  border: 1px solid #fff;
  color: #fff;
}
.enabled-categories
  .select2-container--default.select2-container--focus
  .select2-selection--multiple {
  border: 1px solid #fff;
}
.enabled-categories .select2-results__option[aria-selected] {
  color: #fff;
}
.select2-results__option[aria-selected] {
  color: #fff;
}
.select2-container--default
  .select2-results__option--highlighted[aria-selected] {
  background: rgba($blue-hover, 0.5);
}
.select2-container--default .select2-results__option[aria-selected='true'] {
  background: rgba($blue-hover, 0.5) !important;
}
.comments {
  list-style: none;
  padding-left: 0;

  .single-comment {
    margin-bottom: 15px;
    border-bottom: 1px solid white;

    .comment-date {
      float: left;
    }
    .comment-author {
      float: right;
    }

    .comment-text {
      margin-top: 10px;
      clear: both;
    }
  }
}

.alerts {
  list-style: none;
  padding-left: 0;

  .single-alert {
    margin-bottom: 10px;
    background: rgba(0, 0, 0, 0.3);
    padding: 10px 15px;

    &.danger {
      background: rgba(191, 57, 51, 0.6);
    }
  }
}
.spend {
  background: rgba(191, 57, 51, 0.8) !important;
}
.load {
  background: rgba(86, 181, 66, 0.8) !important;
}
@-webkit-keyframes autofill {
  to {
    color: white;
    background: transparent;
  }
}

input:-webkit-autofill {
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}
